// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Carousel from 'antd/lib/carousel'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import LeadSlide from './LeadSlide'
import Slide from './Slide'
import VideoSlide from './VideoSlide'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({
  '&.ant-carousel': {
    minWidth: '100%',
    maxWidth: '100%',
    maxHeight: '100vh !important',

    '& .slick-list': {
      maxHeight: '100vh !important',
    },
  },

  '@media(min-width: 992px)': {
    '& .ant-carousel .slick-dots': {
      width: '44px !important',
      right: '12px !important',
      top: '50% !important',
      bottom: 'auto !important',
      transform: 'translateY(-50%) !important',
      height: 'auto !important',
    },

    '& .ant-carousel .slick-dots li button': {
      width: '40px !important',
      height: '40px !important',
      marginBottom: '12px !important',
      borderRadius: '50% !important',
      background: 'hsla(0,0%,100%,70%) !important',
      opacity: '1 !important',
      border: '2px solid hsla(213.8,100%,29.2%,70%)',
    },

    '& .slide-info': {
      position: 'absolute',
      bottom: 30,
      left: 0,
      width: '62%',
      textAlign: 'left',
      padding: 16,
      background: '#FFC700',
      boxShadow: '6px 6px rgba(0,67,148,1)',
    },
  },

  '@media(max-width: 992px)': {
    '& .ant-carousel .slick-dots': {
      height: '20px !important',
      bottom: '110px !important',
    },

    '& .ant-carousel .slick-dots li button': {
      width: '20px !important',
      height: '20px !important',
      marginRight: '10px !important',
      borderRadius: '50% !important',
      background: 'hsla(0,0%,100%,70%) !important',
      opacity: '1 !important',
      border: '2px solid hsla(213.8,100%,29.2%,70%)',
    },

    '& .slide-info': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 16,
      paddingRight: 16,
      background: '#FFC700',
      boxShadow: '6px 6px rgba(0,67,148,1)',
      marginBottom: 6,
      height: 100,
      display: 'flex',
      alignItems: 'center',
    },
  },

  '& .ant-carousel .slick-dots li.slick-active button': {
    background: 'hsla(213.8,100%,29.2%,100%) !important',
  },
}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** CarouselX */
const CarouselX = ({ data }) => {
  return (
    <Carousel className={style}>
      <LeadSlide
        data={data.slide14.childImageSharp.fluid}
        title=" * ADMISSION OPEN 2025-26 *"   
        text=" Old Commitment.. New Inspiration!  Continuing our journey as Cambridge Innovative School for girls and boys."
        link="/about-us"  
      />
      <Slide
        data={data.slideCamp.childImageSharp.fluid}
        title="Together We Learn, Together We Change"
        text="The new age school includes an international perspective with STEAM integrated curriculum, innovative classrooms, Artificial Intelligence based learning with a much wider choice of fields for study."
        link="/academics"
      />
      
      <Slide
        data={data.slide13.childImageSharp.fluid}
        title="Why Innovative School?"
        text="Cambridge believes in providing education in real sense. It is a temple of
          learning, where students are taught how to think and not what to think."
        link="/why-us"
      />

      
      <Slide
        data={data.slide9.childImageSharp.fluid}
        title="I Accept Challenges"
        text="Every child is equipped with the confidence to pursue the dreams and realize them."
        link="/general-policy-and-admission-procedure"
      />
      
      <Slide
        data={data.slide10.childImageSharp.fluid}
        title="Community Outreach Programs"
        text="We work together with many great organisations to improve the life conditions of people."
        link="/schools-for-good"
      />

      <Slide
        data={data.slide1.childImageSharp.fluid}
        title="Growth Mindset"
        text="Our campus is in the heart of Jalandhar city inspires an inclusive, enriching and global dimension."
        link="/stories"
      />

      <Slide
        data={data.slide12.childImageSharp.fluid}
        title="Parent Feedback"
        text="Want us to improve? Give us feedback."
        link="/parent-feedback"
      />
      <Slide
        data={data.slide7.childImageSharp.fluid}
        title="From Our Alumni…"
        text="Cambridge Innovative School has consistently educated hundreds of students, empowering them to evolve into individuals of substance."
        link="/alumni-speak"
      />

     
      

    </Carousel>
  )
}

CarouselX.propTypes = {}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CarouselX
