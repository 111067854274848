// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import TextLoop from 'react-text-loop'

import Video from '@bodhi-project/components/lib/Video'
import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../../Link'
import Copy from '../../Copy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({
  marginTop: 0,
  marginBottom: 30,
  position: 'relative',
}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
 * [description]
 * @return {[type]} [description]
 */
const WhyUs = ({ data }) => {
  return (
    <div className={style}>
      <Img
        fluid={data.blob.childImageSharp.fluid}
        title="blob"
        alt="blob"
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: 10,
        }}
      />
      <div
        style={{
          position: 'relative',
          zIndex: 100,
          padding: '56px 6.25% 56px 6.25%',
        }}
      >
        <Copy>
          <h2>Why Us?</h2>
          <p>
            We are committed towards a holistic education which aims to instil
            appropriate and desirable ethical values to make students productive
            and responsible members of society. We provide student-centered
            education of the highest quality in a creative and cooperative
            environment.
            <br />
            <Link to="/why-us">Read more ⇝</Link>
          </p>
        </Copy>
        <Division golden>
          <Video
            url="https://www.youtube.com/watch?v=E9FlGtT61eA"
            style={{ marginBottom: 0, border: 'unset' }}
          />
          <Copy className="desktop-only">
            <TextLoop noWrap={false} interval={6000} mask>

            <div>
                <p style={{ marginBottom: 0 }}>
                  <small>
                    <i>Highlighted Stories</i>
                  </small>
                </p>
                <hr
                  style={{
                    borderTop: '6px solid hsla(213.8,100%,29.2%,100%)',
                    width: '12%',
                    marginLeft: 0,
                  }}
                />
                <h2 className="mask-h6" style={{ marginBottom: 2 }}>
                  Youth Parliament
                </h2>

                <Img
                  fluid={data.youth.childImageSharp.fluid}
                  title="Youth Parliament 2022"
                  alt="Youth Parliament 2022"
                  style={{ marginBottom: 2 }}
                />
                 
                <p style={{ marginBottom: 0 }}>
                  Presenting City's first Youth Parliament. 
              An #initiative of today's changemakers for betterment of tomorrow, 
              Vision to enlighten the country as leaders of tomorrow vocalise their opinions. 
               Presenting India's future commanders in the #YouthParliament.
                </p>
              </div>
              <div>
                <p style={{ marginBottom: 0 }}>
                  <small>
                    <i>Highlighted Stories</i>
                  </small>
                </p>
                <hr
                  style={{
                    borderTop: '6px solid hsla(213.8,100%,29.2%,100%)',
                    width: '12%',
                    marginLeft: 0,
                  }}
                />
                <h2 className="mask-h6" style={{ marginBottom: 2 }}>
                  TEDxYouth@CISJalandhar
                </h2>
                <Img
                  fluid={data.tedx.childImageSharp.fluid}
                  title="TEDxYouth@CISJalandhar"
                  alt="TEDxYouth@CISJalandhar"
                  style={{ marginBottom: 2 }}
                />
                <p style={{ marginBottom: 0 }}>
                  Our students organised <i>Kaliedoscope of Perspectives</i>{' '}
                  with an endeavour to bring together inspired speakers and give
                  them a platform to express ‘Ideas worth Spreading’ and help
                  people learn about different perspectives.
                </p>
              </div>
              <div>
                <p style={{ marginBottom: 0 }}>
                  <small>
                    <i>Highlighted Stories</i>
                  </small>
                </p>
                <hr
                  style={{
                    borderTop: '6px solid hsla(213.8,100%,29.2%,100%)',
                    width: '12%',
                    marginLeft: 0,
                  }}
                />
                <h2 className="mask-h6" style={{ marginBottom: 2 }}>
                  Diplomathon
                </h2>
                <Img
                  fluid={data.diplomathon.childImageSharp.fluid}
                  title="Diplomathon"
                  alt="Diplomathon"
                  style={{ marginBottom: 2 }}
                />
                <p style={{ marginBottom: 0 }}>
                  We organised Diplomathon Jalandhar Edition! Asia’s
                  largest Student conference Diplomathon Flagship Series came to
                  North India for its first Jalandhar Edition.
                </p>
              </div>

              
            </TextLoop>
          </Copy>
        </Division>
      </div>
    </div>
  )
}

WhyUs.propTypes = {}

// --------------------------------------------------------------------- Export
export default WhyUs
