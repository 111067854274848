// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../../Link'
import Copy from '../../Copy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({ marginTop: 30, marginBottom: 30 }).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
 * [description]
 * @return {[type]} [description]
 */
const Introduction = ({ data }) => {
  console.log('student',data)
  return (
    <div className={style}>
      <Copy>
        <h2>More about us…</h2>
        <p>
          Do join us in our effort to raise confident, caring young people who are creative, collaborative, and committed to their learning in this new world.
        </p>
      </Copy>
      <Division byThree>
        <Copy>
          <Img
            fluid={data.chairman.childImageSharp.fluid}
            title="Ajay Bhatia, Chairman, Learning Wings"
            alt="Ajay Bhatia, Chairman, Learning Wings"
          />
          <h3>From Our Chairman</h3>
          <p>
            <i>
              We aspire to foster and fiercely protect the bright spontaneity in
              each student. Students are encouraged to develop their instruments of
              knowledge by using all possible sources from modern technology to
              traditional methods and gain essential skills to be successful in
              whatever they choose to be.
            </i>
          </p>
          <p style={{ marginBottom: 0 }}>
            <Link to="/chairmans-message">Read more ⇝</Link>
          </p>
        </Copy>
        <Copy>
          <Img
            fluid={data.teacher1.childImageSharp.fluid}
            title="Careers"
            alt="Careers"

          />
          <h3>Work With Us</h3>
          <p>
            Recognized as an employer of choice, we have an outstanding,
            cohesive and diverse professional staff committed to continuous
            growth and excellence in education.
          </p>
          <p style={{ marginBottom: 0 }}>
            <Link to="/careers">Read more ⇝</Link>
          </p>
        </Copy>
        <Copy>
          <Img
            fluid={data.student3.childImageSharp.fluid}
            title="Accepting Applications"
            alt="Accepting Applications"
          />
          <h3>Accepting Applications</h3>
          <p>
            Admission for this academic year 2024-25 is open. We are accepting
            the applications for all classes. For any further details you can
            contact us or visit our school.
          </p>
          <p style={{ marginBottom: 0 }}>
            <Link to="/general-policy-and-admission-procedure">
              Read more ⇝
            </Link>
          </p>
        </Copy>
      </Division>
    </div>
  )
}

Introduction.propTypes = {}

// --------------------------------------------------------------------- Export
export default Introduction
