// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../../Link'
import Copy from '../../Copy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({
  marginTop: 45,
  marginBottom: 30,

  '& .block': {
    position: 'relative',
    display: 'block',

    '& h2': {
      position: 'absolute',
      bottom: 7,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& span': {
        paddingTop: 7,
        paddingBottom: 7,
        marginBottom: 0,
        width: '90%',
        textAlign: 'center',
      },
    },

    '& .block-cover': {
      transition: 'all 0.6s cubic-bezier(0.78, 0.14, 0.15, 0.86)',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      opacity: 0,
      alignItems: 'center',
      zIndex: 10,
      display: 'flex',
      padding: 20,
      color: '#fff !important',
      textAlign: 'center !important',
    },

    '&:hover': {
      '& .block-cover': {
        opacity: 1,
      },
    },
  },
}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
 * [description]
 * @return {[type]} [description]
 */
const WhyUs = ({ data }) => {
  return (
    <div className={style}>
      <Copy>
        <h2>The Cambridge Difference</h2>
        <p style={{ marginBottom: 10 }}>
          Our history, our unusual ethos of teaching and learning and our
          careful balance of intellectual firepower with courtesy and support
          set us apart.
        </p>
      </Copy>
      <Division byThree>
        <Link to="/academics" className="block">
          <Img
            fluid={data.meaningfulEdutech.childImageSharp.fluid}
            title="blob"
            alt="blob"
          />
          <h2 className="mask-h6" style={{ color: '#fff' }}>
            <span style={{ background: '#FF800D' }}>
              Community First, Not Technology!
            </span>
          </h2>
          <div className="block-cover" style={{ background: '#FF800D' }}>
            <p style={{ color: '#fff' }}>
              Our aim is not to hand in technology tools but to nurture a
              digital participatory culture.
              <br />
              <br />
              Click to discover more ⇝
            </p>
          </div>
        </Link>
        <Link to="/schools-for-good" className="block">
          <Img
            fluid={data.schoolsForGood.childImageSharp.fluid}
            title="blob"
            alt="blob"
          />
          <h2 className="mask-h6" style={{ color: '#fff' }}>
            <span style={{ background: '#59955C' }}>Schools For Good</span>
          </h2>
          <div className="block-cover" style={{ background: '#59955C' }}>
            <p style={{ color: '#fff' }}>
              Our school is committed to community service and we are proud that
              the school is a center of dedicated action for the good of people.
              <br />
              <br />
              Click to discover more ⇝
            </p>
          </div>
        </Link>
        <Link to="/values" className="block">
          <Img
            fluid={data.everOnwards.childImageSharp.fluid}
            title="blob"
            alt="blob"
          />
          <h2 className="mask-h6" style={{ color: '#fff' }}>
            <span style={{ background: '#FF800D' }}>Ever Onwards</span>
          </h2>
          <div className="block-cover" style={{ background: '#FF800D' }}>
            <p style={{ color: '#fff' }}>
              Every child is equipped with the confidence to pursue the dreams
              and realize them. In this world of turmoil and unrest they learn
              to pave their own path to success.
              <br />
              <br />
              Click to discover more ⇝
            </p>
          </div>
        </Link>
      </Division>
    </div>
  )
}

WhyUs.propTypes = {}

// --------------------------------------------------------------------- Export
export default WhyUs
