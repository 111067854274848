// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
// import { css } from 'glamor'

import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'
import values from 'lodash/values'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'
import StackGrid from 'react-stack-grid'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** NewStackGrid */
class NewStackGrid extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)

    this.state = {
      alreadyRendered: false,
    }

    this.grid = undefined
    this.reRender = this.reRender.bind(this)
  }

  /** reRender */
  reRender() {
    const { alreadyRendered } = this.state
    if (alreadyRendered === false) {
      this.setState({ alreadyRendered: true })
      setTimeout(() => {
        this.grid.updateLayout()
        this.setState({ alreadyRendered: false })
      }, 2000)
    }
  }

  /** standard renderer */
  render() {
    const { photos } = this.props
    const { alreadyRendered } = this.state

    return (
      <StackGrid
        columnWidth="34%"
        duration={480}
        gutterWidth={4}
        gutterHeight={8}
        monitorImagesLoaded={false}
        gridRef={grid => (this.grid = grid)}
        onLayout={alreadyRendered === false && this.reRender}
      >
        {map(photos, (photo, index) => (
          <Img fluid={photo.childImageSharp.fluid} />
        ))}
      </StackGrid>
    )
  }
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default NewStackGrid
