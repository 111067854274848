// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../../Link'
import Copy from '../../Copy'
import NewStackGrid from '../../commons/NewStackGrid'

import pluckImages from '../../../methods/pluckImages'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({
  position: 'relative',
  marginTop: 45,
  marginBottom: 30,
}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
 * [description]
 * @return {[type]} [description]
 */
const WhyUs = ({ data }) => {
  const photos = pluckImages(data, 'galleryImage')

  return (
    <div className={style}>
      <Copy>
        <h2>A Usual Day At CIS…</h2>
        <p style={{ position: 'absolute', top: 0, right: 0 }}>
          <strong>
            <Link to="/a-day-at-cis">See more ⇝</Link>
          </strong>
        </p>
      </Copy>
      <NewStackGrid photos={photos} />
    </div>
  )
}

WhyUs.propTypes = {}

// --------------------------------------------------------------------- Export
export default WhyUs
