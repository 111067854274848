// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------

import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'
import values from 'lodash/values'

/**
 * [Checks if a value exists in an array]
 * @param  {array} array [The array to lookup a value in]
 * @param  {string} value [The value]
 * @return {boolean}       [Returns true or false]
 */
const pluckImages = (object, dataKey = 'image') => {
  return values(pickBy(object, (value, key) => startsWith(key, dataKey)))
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default pluckImages
