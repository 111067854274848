// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Carousel from 'antd/lib/carousel'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
// import Link from '../../Link'
import Copy from '../../Copy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({
  position: 'relative',
  border: '1px solid #004195',
  borderRadius: 12,
  padding: 12,

  marginTop: 45,
  marginBottom: 30,
}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
 * [description]
 * @return {[type]} [description]
 */
const WhyUs = ({ data }) => {
  return (
    <div className={style}>
      <Copy>
        <h2>Where do Cambridge students go after school?</h2>
        <p>
          Cambridge International Schools have consistently educated
          hundreds of students, empowering them to evolve into individuals of
          substance. Be they doctors, engineers, teachers, nurses, homemakers,
          scientists or corporate chiefs, CIS graduates are sure to make their
          mark.
        </p>
      </Copy>
      <Carousel
        autoplay
        infinite
        slidesToShow={4}
        slidesToScroll={2}
        dots={false}
        swipeToSlide
        pauseOnHover={false}
      >
        <Img
          fluid={data.iitBombay.childImageSharp.fluid}
          title="IIT Bombay"
          alt="IIT Bombay"
        />
        <Img
          fluid={data.torontoUniversity.childImageSharp.fluid}
          title="Univerity of Toronto"
          alt="Univerity of Toronto"
        />
        <Img
          fluid={data.vit.childImageSharp.fluid}
          title="Vellore Institute of Technology"
          alt="Vellore Institute of Technology"
        />
        <Img
          fluid={data.mcmasterUniversity.childImageSharp.fluid}
          title="McMaster Univerity"
          alt="McMaster Univerity"
        />
        <Img
          fluid={data.mcgillUniversity.childImageSharp.fluid}
          title="McGill Univerity"
          alt="McGill Univerity"
        />
        <Img
          fluid={data.ubc.childImageSharp.fluid}
          title="Univerity of British Columbia"
          alt="Univerity of British Columbia"
        />
        <Img
          fluid={data.nift.childImageSharp.fluid}
          title="National Institute of Fasion Technology"
          alt="National Institute of Fasion Technology"
        />
        <Img
          fluid={data.christUniversity.childImageSharp.fluid}
          title="Christ Univerity"
          alt="Christ Univerity"
        />
        <Img
          fluid={data.delhiUniversity.childImageSharp.fluid}
          title="Delhi Univerity"
          alt="Delhi Univerity"
        />
        <Img
          fluid={data.iim.childImageSharp.fluid}
          title="Indian Institute of Management"
          alt="Indian Institute of Management"
        />
        <Img
          fluid={data.stanford.childImageSharp.fluid}
          title="Univerity of Stanford"
          alt="Univerity of Stanford"
        />
        <Img
          fluid={data.berkley.childImageSharp.fluid}
          title="Berkley Univerity"
          alt="Berkley Univerity"
        />
      </Carousel>
    </div>
  )
}

WhyUs.propTypes = {}

// --------------------------------------------------------------------- Export
export default WhyUs
