// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../../Link'
import Copy from '../../Copy'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({ marginTop: 30, marginBottom: 30 }).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
 * [description]
 * @return {[type]} [description]
 */
const Introduction = ({ data }) => {
  return (
    <div className={style}>
      <Copy>
        <h2>Welcome to Cambridge Innovative School!</h2>
        <p>
          Cambridge Innovative School, originally known as Cambridge International School for Girls,
           is the flagship school of Learning Wings Education Systems. It was started in the year 2005,
          with a lofty dream of empowering young girls to be the leaders in all
          capacities and shape this world to be a better place.
        </p>
        <p>
          <Link to="/about-us">Read more ⇝</Link>
        </p>
      </Copy>
      <Division byThree>
        <Copy>
          <Img
            fluid={data.principal.childImageSharp.fluid}
            title="Kiranjoth Dhillon, Principal, Cambridge Innovative School"
            alt="Kiranjoth Dhillon, Principal, Cambridge Innovative School"
          />
          <h3>Principal's Welcome</h3>
          <p>
            <i>
              Thank you for your interest in Cambridge Innovative School.
              Within these pages we hope to convey the spirit of our school-its
              warmth, purpose, thought and soul. We invite you to experience the
              encouraging, concerned and joyous atmosphere here.
            </i>
          </p>
          <p style={{ marginBottom: 0 }}>
            <Link to="/principals-message">Read more ⇝</Link>
          </p>
        </Copy>
        <Copy>
          <Img
            fluid={data.curriculum1.childImageSharp.fluid}
            title="Curriculum"
            alt="Curriculum"
          />
          <h3>Curriculum</h3>
          <p>
            The Cambridge Innovative School Curriculum has been designed
            keeping in mind the pedagogic needs of the regions our schools are
            based in and connecting it to international standards. Our
            curriculum has been developed by carrying out ongoing small-scale
            research projects, pilot projects and action research.
          </p>
          <p style={{ marginBottom: 0 }}>
            <Link to="/academics">Read more ⇝</Link>
          </p>
        </Copy>
        <Copy>
          <Img
            fluid={data.infrastructure.childImageSharp.fluid}
            title="Infrastructure"
            alt="Infrastructure"
          />
          <h3>Infrastructure</h3>
          <p>
            Ample opportunities are made available to students to engage in
            academic and other activities. Our goal has been to maximize the use
            of space in an environmentally responsible and sustainable fashion.
          </p>
          <p style={{ marginBottom: 0 }}>
            <Link to="/infrastructure">Read more ⇝</Link>
          </p>
        </Copy>
      </Division>
    </div>
  )
}

Introduction.propTypes = {}

// --------------------------------------------------------------------- Export
export default Introduction
