// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// import map from "lodash/map";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** LeadSlide */
const LeadSlide = props => {
  const { data, title, text, link } = props

  return (
    <div style={{ maxHeight: 'calc( 100vh - 100px )' }}>
      <Img fluid={data} title={title} alt={text} />
      <div className="slide-info">
        <header>
          <h1
            className="mask-h4"
            style={{
              color: '#1a1a1a',
            }}
          >
            <span style={{ fontSize: '90%' }}>{title}</span>
          </h1>
          <p style={{ marginBottom: 0 }}>
            <span style={{ fontSize: '90%' }}>
              {text}
              &nbsp;
              <Link
                to={link}
                style={{
                  color: '#1a1a1a',
                  textDecoration: 'underline',
                  fontStyle: 'italic',
                }}
              >
                Learn More ⇝
              </Link>
            </span>
          </p>
        </header>
      </div>
    </div>
  )
}

LeadSlide.propTypes = {}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default LeadSlide
