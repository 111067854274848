// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Video from '@bodhi-project/components/lib/Video'
import Division from '@bodhi-project/components/lib/Division'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
 
import Carousel from '../components/commons/Carousel'

import Introduction from '../components/blocks/homepage/Introduction'
import WhyUs from '../components/blocks/homepage/WhyUs'
import WhyUnique from '../components/blocks/homepage/WhyUnique'
import MiniGallery from '../components/blocks/homepage/MiniGallery'
import WhereDoStudentsGo from '../components/blocks/homepage/WhereDoStudentsGo'
import More from '../components/blocks/homepage/More'


import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

const pageData = {
  pageTitle: 'Home',
  nakedPageSlug: '',
  pageAbstract:
    'We invite you to experience the encouraging, concerned and joyous atmosphere here.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    slide1: file(relativePath: { eq: "carousel/slide1.jpg" }) {
      ...defaultImage
    }
    slide2: file(relativePath: { eq: "carousel/slide2.jpg" }) {
      ...defaultImage
    }
    slide3: file(relativePath: { eq: "carousel/slide3.png" }) {
      ...defaultImage
    }
    slide4: file(relativePath: { eq: "carousel/slide4.jpg" }) {
      ...defaultImage
    }
    slide5: file(relativePath: { eq: "carousel/slide5.jpg" }) {
      ...defaultImage
    }
    slide6: file(relativePath: { eq: "carousel/slide6.jpg" }) {
      ...defaultImage
    }
    slide7: file(relativePath: { eq: "carousel/slide7.jpg" }) {
      ...defaultImage
    }
    slide8: file(relativePath: { eq: "carousel/slide8.jpg" }) {
      ...defaultImage
    }
    slide9: file(relativePath: { eq: "carousel/slide9.jpg" }) {
      ...defaultImage
    }
    slide10: file(relativePath: { eq: "carousel/slide10.jpg" }) {
      ...defaultImage
    }
    slide11: file(relativePath: { eq: "carousel/slide11.jpg" }) {
      ...defaultImage
    }

    slide12: file(relativePath: { eq: "carousel/slide12.jpg" }) {
      ...defaultImage
    }

    slide13: file(relativePath: { eq: "carousel/slide13.jpg" }) {
      ...defaultImage
    }

    slide14: file(relativePath: { eq: "carousel/slide14.jpg" }) {
      ...defaultImage
    }




    slideCamp: file(relativePath: { eq: "carousel/school-building.jpg" }) {
      ...defaultImage
    }
    principal: file(relativePath: { eq: "homepage/principal2.jpg" }) {
      ...defaultImage
    }
    curriculum1: file(relativePath: { eq: "homepage/curriculum1.jpg" }) {
      ...defaultImage
    }
    infrastructure: file(relativePath: { eq: "homepage/infrastructure.jpg" }) {
      ...defaultImage
    }

    infrastructure: file(relativePath: { eq: "homepage/infrastructure.jpg" }) {
      ...defaultImage
    }
    
    blob: file(relativePath: { eq: "homepage/blob.png" }) {
      ...defaultImage
    }
    diplomathon: file(relativePath: { eq: "homepage/diplomathon.jpg" }) {
      ...defaultImage
    }
    tedx: file(relativePath: { eq: "homepage/tedx.jpg" }) {
      ...defaultImage
    }
    youth: file(relativePath: { eq: "homepage/youth.jpg" }) {
      ...defaultImage
    }

    meaningfulEdutech: file(
      relativePath: { eq: "homepage/meaningful-edutech.jpg" }
    ) {
      ...defaultImage
    }
    schoolsForGood: file(
      relativePath: { eq: "homepage/schools-for-good.jpg" }
    ) {
      ...defaultImage
    }
    everOnwards: file(relativePath: { eq: "homepage/ever-onwards.jpg" }) {
      ...defaultImage
    }
    galleryImage1: file(relativePath: { eq: "gallery/image14aa.jpg" }) {
      ...defaultImage
    }
    galleryImage2: file(relativePath: { eq: "gallery/image14bb.jpg" }) {
      ...defaultImage
    }
    galleryImage3: file(relativePath: { eq: "gallery/image14gg.jpg" }) {
      ...defaultImage
    }
    galleryImage4: file(relativePath: { eq: "gallery/image14dd.jpg" }) {
      ...defaultImage
    }
    galleryImage5: file(relativePath: { eq: "gallery/image14jj.jpg" }) {
      ...defaultImage
    }
    student: file(relativePath: { eq: "homepage/student2.jpg" }) {
      ...defaultImage
    }
    student3: file(relativePath: { eq: "homepage/student3.jpg" }) {
      ...defaultImage
    }
    teacher: file(relativePath: { eq: "homepage/teacher.jpg" }) {
      ...defaultImage
    }
    teacher1: file(relativePath: { eq: "homepage/teacher1.jpg" }) {
      ...defaultImage
    }
    chairman: file(relativePath: { eq: "homepage/chairman.jpg" }) {
      ...defaultImage
    }
    iitBombay: file(relativePath: { eq: "homepage/iit-bombay.jpg" }) {
      ...defaultImage
    }
    torontoUniversity: file(
      relativePath: { eq: "homepage/toronto-university.jpg" }
    ) {
      ...defaultImage
    }
    vit: file(relativePath: { eq: "homepage/vit.jpg" }) {
      ...defaultImage
    }
    mcmasterUniversity: file(
      relativePath: { eq: "homepage/mcmaster-university.jpg" }
    ) {
      ...defaultImage
    }
    mcgillUniversity: file(
      relativePath: { eq: "homepage/mcgill-university.jpg" }
    ) {
      ...defaultImage
    }
    ubc: file(relativePath: { eq: "homepage/ubc.jpg" }) {
      ...defaultImage
    }
    nift: file(relativePath: { eq: "homepage/nift.jpg" }) {
      ...defaultImage
    }
    christUniversity: file(
      relativePath: { eq: "homepage/christ-university.jpg" }
    ) {
      ...defaultImage
    }
    delhiUniversity: file(
      relativePath: { eq: "homepage/delhi-university.jpg" }
    ) {
      ...defaultImage
    }
    iim: file(relativePath: { eq: "homepage/iim.jpg" }) {
      ...defaultImage
    }
    stanford: file(relativePath: { eq: "homepage/stanford.jpg" }) {
      ...defaultImage
    }
    berkley: file(relativePath: { eq: "homepage/berkley.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const style = css({}).toString()

// TO Add somewhere:
// https://www.youtube.com/watch?v=KFNVP0wtwBY
// https://www.youtube.com/watch?v=HJSpSzc2MQ0
// https://www.youtube.com/watch?v=Jsn52KbOhv4
// https://www.youtube.com/watch?v=E9FlGtT61eA

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** IndexPage */


const IndexPage = props => (
  <StandardPage className={style} seoData={seoData}>
 
    <Carousel data={props.data} />
    <Introduction data={props.data} />
    <WhyUs data={props.data} />
    <WhereDoStudentsGo data={props.data} />
    <Fragment>
      <br />
      <Division>
        <Video url="https://youtu.be/iPYnuld01AY?si=kNKTtNzfA_39vhV6" />
        <Video url="https://youtu.be/q35zJO_NV3I?si=jDeONkKXMVV2irfV" />
      </Division> 
      <br />
    </Fragment>
    <WhyUnique data={props.data} />
    <MiniGallery data={props.data} />
    <More data={props.data} />
  </StandardPage>
      );
 
  

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default IndexPage
